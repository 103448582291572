class OFormJobPosting {
  /** @param {HTMLFormElement} element */
  constructor(element) {
    this.element = element;

    element.addEventListener('submit', this.onSubmit.bind(this));
  }

  set loading(isLoading = true) {
    const {
      element,
    } = this;
    const inputElements = [...element.elements];
    if (isLoading === true) {
      element.classList.add('-loading');
    } else {
      element.classList.remove('-loading');
    }
    inputElements.forEach((inputElement) => {
      inputElement.toggleAttribute('disabled', isLoading);
    });
  }

  get loading() {
    return this.element.classList.contains('-loading');
  }

  hideError() {
    const errorElement = this.element.querySelector('.o-form-job-posting__error');
    errorElement.toggleAttribute('hidden', true);
    errorElement.innerText = '';
  }

  showError(message) {
    const errorElement = this.element.querySelector('.o-form-job-posting__error');

    errorElement.innerText = message;
    errorElement.toggleAttribute('hidden', false);
  }

  showSucces(message) {
    const inputElements = [...this.element.elements];
    inputElements.forEach((inputElement) => {
      inputElement.toggleAttribute('readonly', true);
    });

    const successElement = this.element.querySelector('.o-form-job-posting__success');

    successElement.innerHTML = message;
    this.element.querySelector('button[type="submit"]').toggleAttribute('hidden', true);
    successElement.toggleAttribute('hidden', false);
  }

  onSubmit(event) {
    event.preventDefault();

    const { element } = this;
    const formData = new FormData(element);

    this.loading = true;

    this.hideError();

    fetch(element.action, {
      method: element.method,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          this.showSucces(data.message);
        } else {
          this.showError(data.message);
        }
      })
      .catch((error) => this.showError(error.message))
      .finally(() => {
        this.loading = false;
      });
  }
}

export default OFormJobPosting;
