class AddToCart {
  constructor(element) {
    const {
      productId,
    } = element.dataset;

    this.element = element;
    this.inputElement = element.querySelector('input');
    this.buttonElement = element.querySelector('button');
    this.cart = window._cart;

    element.addEventListener('submit', (event) => {
      const {
        value,
      } = this.inputElement;
      event.preventDefault();
      this.setLoading();
      (async () => {
        const {
          cart,
        } = this;
        await cart.add(productId, value).catch((error) => {
          console.info(error);
          alert('Fatal Error.');
        });
        this.unsetLoading();

        // close Cart after x seconds
        setTimeout(() => {
          if (cart.isMouseOver === false) {
            cart.hide();
          }
        }, 4 * 1000);
      })();
    });
  }

  setLoading() {
    const {
      element,
      inputElement,
      buttonElement,
    } = this;

    element.classList.add('-loading');
    inputElement.disabled = true;
    buttonElement.disabled = true;
  }

  unsetLoading() {
    const {
      element,
      inputElement,
      buttonElement,
    } = this;

    element.classList.remove('-loading');
    inputElement.disabled = false;
    buttonElement.disabled = false;
  }
}

export default AddToCart;
