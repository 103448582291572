class ThumbProductCategory {
  constructor(element) {
    this.element = element;
    this.imgElements = element.querySelectorAll('.m-thumb-product-category__images img');
    this.currentImg = 0;
    this.multiplier = 0;
    this.minTimeout = 4000;

    setTimeout(() => {
      this.changeImage();
    }, this.randomTimeout());
  }

  randomTimeout() {
    const {
      multiplier,
      minTimeout,
    } = this;
    return minTimeout + Math.random() * multiplier;
  }

  changeImage() {
    const {
      imgElements,
      currentImg,
    } = this;

    this.currentImg = currentImg + 1;
    if (this.currentImg >= imgElements.length) {
      this.currentImg = 0;
    }

    for (let i = 0; i < imgElements.length; i += 1) {
      if (i === this.currentImg) {
        imgElements[i].classList.remove('-hidden');
      } else {
        imgElements[i].classList.add('-hidden');
      }
    }

    setTimeout(() => {
      this.changeImage();
    }, this.randomTimeout());
  }
}

export default ThumbProductCategory;
