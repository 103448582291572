class CheckoutAbstract {
  submit(formElement) {
    return fetch('/shop-api/checkout', {
      method: 'POST',
      credentials: 'same-origin',
      body: new FormData(formElement),
    });
  }
}

export default CheckoutAbstract;
