class ProductImages {
  constructor(element) {
    let currentCount = 0;
    const containerElement = element.querySelector('.m-product-images__container');
    const figureElementsCount = containerElement.childElementCount;

    const counterElement = document.createElement('div');

    if (figureElementsCount > 1) {
      counterElement.classList.add('m-product-images__counter');
      counterElement.innerText = `1/${figureElementsCount}`;
      element.appendChild(counterElement);
    }

    function getCurrentCounter(event) {
      return (Math.round(containerElement.scrollLeft / window.innerWidth) + 1);
    }

    function updateCounter() {
      currentCount = getCurrentCounter();
      counterElement.innerText = `${currentCount}/${figureElementsCount}`;
    }

    window.addEventListener('resize', updateCounter, {
      passive: true,
    });
    containerElement.addEventListener('scroll', updateCounter, {
      passive: true,
    });

    if (figureElementsCount > 1) {
      updateCounter();
    }
  }
}

export default ProductImages;
