import Cart from './components/Cart';
import Checkout from './components/Checkout';
import FormDownload from './components/FormDownload';
import ThumbsHome from './components/ThumbsHome';
import AddToCart from './components/AddToCart';
import Nav from './components/Nav';
import Subnav from './components/Subnav';
import SubnavFixed from './components/SubnavFixed';
import ProductImages from './components/ProductImages';
import ThumbProductCategory from './components/ThumbProductCategory';
import MFileInput from './components/MFileInput';
import MFooterScrollToTop from './components/MFooterScrollToTop';
import OFormJobPosting from './components/OFormJobPosting';

// import './components/cookie-consent';

const mNavElement = document.querySelector('.m-nav');
const mCartElement = document.querySelector('.m-cart');
const mAddToCartElements = document.querySelectorAll('.m-add-to-cart');
const mSubnavElements = document.querySelectorAll('.m-subnav');
const mProductImagesElements = document.querySelectorAll('.m-product-images');
const mFileInputElements = document.querySelectorAll('.m-file-input');
const mThumbProductCategory = document.querySelectorAll('.m-thumb-product-category');
const oSubnavFixedElements = document.querySelectorAll('.o-subnav-fixed');
const oFormJobPostingElement = document.querySelector('.o-form-job-posting');
const oFormCheckoutElement = document.querySelector('.o-form-checkout');
const oFormDownloadElement = document.querySelector('.o-form-download');
const oThumbsHomeElements = document.querySelectorAll('.o-thumbs-home');
const mFooterScrollToTopElement = document.querySelector('.m-footer-scroll-to-top');

if (mNavElement) {
  new Nav(mNavElement);
}
if (mCartElement) {
  window._cart = new Cart(mCartElement);
}
if (oFormJobPostingElement) {
  new OFormJobPosting(oFormJobPostingElement);
}
if (oFormCheckoutElement) {
  new Checkout(oFormCheckoutElement);
}
if (oFormDownloadElement) {
  new FormDownload(oFormDownloadElement);
}
if (mFooterScrollToTopElement) {
  new MFooterScrollToTop(mFooterScrollToTopElement);
}
if (mFooterScrollToTopElement) {
  new MFooterScrollToTop(mFooterScrollToTopElement);
}

mAddToCartElements.forEach((element) => new AddToCart(element));
mSubnavElements.forEach((element) => new Subnav(element));
oThumbsHomeElements.forEach((element) => new ThumbsHome(element));
oSubnavFixedElements.forEach((element) => new SubnavFixed(element));
mProductImagesElements.forEach((element) => new ProductImages(element));
mThumbProductCategory.forEach((element) => new ThumbProductCategory(element));
mFileInputElements.forEach((element) => new MFileInput(element));

function detectIE() {
  const { userAgent } = window.navigator;
  if (userAgent.includes('MSIE ') || userAgent.includes('Trident/')) {
    return true;
  }

  return false;
}

if (detectIE()) {
  document.querySelector('body').classList.add('-ie');
}
