class Nav {
  constructor(element) {
    const oHeaderElement = document.querySelector('.o-header');
    const bpNoHamburgerNav = 832;

    this.element = element;
    this.buttonElement = element.previousElementSibling;
    this.mSubnavElements = element.querySelectorAll('.m-subnav');
    this.oHeaderBackdropElement = oHeaderElement.querySelector('.o-header__backdrop');

    function onResize() {
      const {
        element,
        buttonElement,
      } = this;
      if (window.matchMedia(`(max-width: ${(bpNoHamburgerNav - 1) / 16}em)`).matches) {
        element.hidden = buttonElement.getAttribute('aria-expanded') === 'false';
      } else {
        element.hidden = false;
      }
    }

    element.addEventListener('transitionend', (event) => {
      if (event.target === element && !element.classList.contains('-expanded')) {
        element.hidden = true;
      }
    });
    this.buttonElement.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'false') {
        this.show();
      } else {
        this.hide();
      }
    });
    document.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'true'
      && event.target.closest('button') !== this.buttonElement
      && event.target.closest('.m-subnav') === null) {
        this.hide();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.hide();
      }
    });
    window.addEventListener('resize', onResize.bind(this), {
      passive: true,
    });

    onResize.bind(this)();
  }

  show() {
    const {
      element,
      buttonElement,
      mSubnavElements,
      oHeaderBackdropElement,
    } = this;

    if (buttonElement.getAttribute('aria-expanded') === 'false') {
      element.hidden = false;
      buttonElement.setAttribute('aria-expanded', 'true');

      mSubnavElements.forEach((mSubnavElement) => {
        mSubnavElement.classList.add('-expanded');
        mSubnavElement.hidden = false;
      });

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          oHeaderBackdropElement.classList.remove('-hidden');
          element.classList.add('-expanded');
        });
      });
    }
  }

  hide() {
    const {
      element,
      buttonElement,
      mSubnavElements,
      oHeaderBackdropElement,
    } = this;

    if (buttonElement.getAttribute('aria-expanded') === 'true') {
      element.classList.remove('-expanded');
      buttonElement.setAttribute('aria-expanded', 'false');
      oHeaderBackdropElement.classList.add('-hidden');

      mSubnavElements.forEach((mSubnavElement) => {
        mSubnavElement.classList.remove('-expanded');
        mSubnavElement.hidden = false;
      });
    }
  }
}

export default Nav;
