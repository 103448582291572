class MFileInput {
  /** @param {HTMLElement} element */
  constructor(element) {
    this.inputElement = element.querySelector('input[type="file"]');
    this.labelElement = element.querySelector('.m-file-input__label');
    this.filesElement = element.querySelector('.m-file-input__files');
    this.filesButtoClearElement = this.filesElement.querySelector('button');
    this.errorFileSizeElement = element.querySelector('.m-file-input__error-files-size');
    this.filesListElement = element.querySelector('.m-file-input__files-list');

    /** @type {?FileList} */
    this.filesList = null;

    this.inputElement.addEventListener('change', this.onChange.bind(this));
    this.filesButtoClearElement.addEventListener('click', () => {
      this.inputElement.value = '';
      this.inputElement.dispatchEvent(new Event('change'));
    });
  }

  createFileList() {
    function formatBytes(bytes, decimals = 0) {
      if (!+bytes) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
    }

    if (this.filesList.length === 0) {
      this.filesListElement.innerHTML = '';
      this.filesElement.toggleAttribute('hidden', true);
      this.labelElement.toggleAttribute('hidden', false);
      this.errorFileSizeElement.toggleAttribute('hidden', true);
    } else {
      let filesSize = 0;

      this.filesListElement.innerHTML = '<ul>';
      // eslint-disable-next-line no-restricted-syntax
      for (const file of this.filesList) {
        /** @var {File} file */
        this.filesListElement.innerHTML += `<li><span>${file.name}</span> <small> ${formatBytes(file.size)}</small></li>`;
        filesSize += file.size;
      }
      this.filesListElement.innerHTML += '</ul>';

      // Checked on server side in job-application route
      // 10 MB = 10 * 1000000
      if (filesSize >= 10 * 1000000) {
        this.errorFileSizeElement.toggleAttribute('hidden', false);
      } else {
        this.errorFileSizeElement.toggleAttribute('hidden', true);
      }

      this.filesElement.toggleAttribute('hidden', false);
      this.labelElement.toggleAttribute('hidden', true);
    }
  }

  onChange(event) {
    console.log(event.target.files);
    this.filesList = [...event.target.files];
    this.createFileList();
  }
}

export default MFileInput;
