class CartAbstract {
  get(url = null) {
    if (!url) {
      const { lang } = document.querySelector('html');
      url = lang === 'de' ? '/shop-api/cart' : `/${lang}/shop-api/cart`;
    }
    return fetch(url, {
      credentials: 'same-origin',
      cache: 'no-store',
    });
  }

  add(item, quantity = 1) {
    const { lang } = document.querySelector('html');
    const url = lang === 'de' ? '/shop-api/cart' : `/${lang}/shop-api/cart`;
    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      cache: 'no-store',
      body: JSON.stringify({
        id: item,
        quantity,
      }),
    });
  }

  update(item, quantity) {
    const { lang } = document.querySelector('html');
    const url = lang === 'de' ? '/shop-api/cart' : `/${lang}/shop-api/cart`;
    return fetch(url, {
      method: 'PATCH',
      credentials: 'same-origin',
      cache: 'no-store',
      body: JSON.stringify({
        id: item,
        quantity,
      }),
    });
  }

  remove(item) {
    const { lang } = document.querySelector('html');
    const url = lang === 'de' ? '/shop-api/cart' : `/${lang}/shop-api/cart`;
    return fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      cache: 'no-store',
      body: JSON.stringify({
        id: item,
      }),
    });
  }
}

export default CartAbstract;
