class FormDownload {
  constructor(element) {
    this.element = element;
    this.containerElement = element.querySelector('.o-form-download__container');
    this.successElement = element.querySelector('.o-form-download__success');

    function removeErrors() {
      element.querySelectorAll('.a-error').forEach((errorElement) => {
        errorElement.remove();
      });
    }

    element.addEventListener('submit', () => {
      removeErrors();
      this.loading = true;
      event.preventDefault();

      (async () => {
        const message = await this.submit();
        if (message) {
          this.showSuccess(message);
        }
        this.loading = false;
      })().catch((message) => {
        this.loading = false;
        this.showGlobalError(message);
      });
    });
  }

  getErrorElement(message) {
    const errorElement = document.createElement('div');
    errorElement.classList.add('a-error');
    errorElement.textContent = message;
    return errorElement;
  }

  showGlobalError(message = 'Fatal error.') {
    const {
      containerElement,
      getErrorElement,
    } = this;
    containerElement.appendChild(getErrorElement(message));
  }

  showSuccess(html) {
    const {
      element,
      successElement,
    } = this;

    successElement.innerHTML = html;
    element.classList.add('-success');

    const firstChild = successElement.firstElementChild;
    if (firstChild) {
      firstChild.scrollIntoView({
        behaviour: 'smooth',
      });
    }
  }

  set loading(isLoading = true) {
    const {
      element,
    } = this;
    const inputElements = [...element.elements];
    if (isLoading === true) {
      element.classList.add('-loading');
    } else {
      element.classList.remove('-loading');
    }
    inputElements.forEach((inputElement) => {
      inputElement.disabled = isLoading;
    });
  }

  get loading() {
    return this.element.classList.contains('-loading');
  }

  submit() {
    const {
      element,
      getErrorElement,
    } = this;

    const url = element.action;

    function showFieldErrors(details) {
      let isFocused = false;
      Object.keys(details).forEach((key) => {
        const inputElement = element.querySelector(`input[name="${key}"]`);
        const message = Object.values(details[key].message)[0];
        if (inputElement) {
          const labelElement = inputElement.closest('label');
          labelElement.appendChild(getErrorElement(message));
          if (!isFocused && inputElement) {
            inputElement.focus();
            isFocused = true;
          }
        } else {
          alert(message || 'Fatal Error.');
        }
      });
    }

    return new Promise(async (resolve, reject) => {
      const inputElements = [...element.elements];

      inputElements.forEach((inputElement) => {
        inputElement.disabled = false;
      });
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        body: new FormData(element),
      });
      inputElements.forEach((inputElement) => {
        inputElement.disabled = true;
      });

      const data = await response.json().catch((error) => {
        console.info(error);
        reject(error.statusText);
      });

      if (data.status === 200) {
        resolve(data.message ? data.message : 'Success');
      } else if (data.code === 'error.merx.fieldsvalidation') {
        showFieldErrors(data.details);
        resolve();
      } else {
        reject(data.message ? data.message : undefined);
      }
    });
  }
}

export default FormDownload;
