import CheckoutAbstract from './CheckoutAbstract';

class Checkout extends CheckoutAbstract {
  constructor(element) {
    super();

    const billingaddressisshippingaddressCheckboxElement = element.querySelector('input[name="billingaddressisshippingaddress"]');
    const shippingAddressElement = document.getElementById('shipping-address');

    this.stripe = null;
    this.paymentMethod = null;
    this.element = element;
    this.containerElement = element.querySelector('.o-form-checkout__container');
    this.submitElement = element.querySelector('button[type="submit"]');

    function removeErrors() {
      element.querySelectorAll('.a-error').forEach((errorElement) => {
        errorElement.remove();
      });
    }

    if (this.submitElement) {
      this.radioPaymentMethodElements = element.querySelectorAll('input[name="paymentMethod"]');
      this.paymentMethodElements = element.querySelectorAll('label[data-payment-method]');
      this.buttonTextDefault = this.submitElement.textContent;

      element.addEventListener('submit', (event) => {
        const {
          paymentMethod,
        } = this;
        removeErrors();

        this.loading = true;
        event.preventDefault();

        (async () => {
          if (paymentMethod === 'credit-card-sca') {
            await this.handleCardPayment();
          } else {
            await this.submit();
          }
          this.loading = false;
        })().catch((message) => {
          this.loading = false;
          this.showGlobalError(message);
        });
      });

      this.radioPaymentMethodElements.forEach((radioPaymentMethodElement) => {
        radioPaymentMethodElement.addEventListener('change', () => {
          removeErrors();
          const paymentMethod = radioPaymentMethodElement.value;
          if (paymentMethod === 'credit-card-sca') {
            this.initStripe();
          }
          const { submitText } = radioPaymentMethodElement.dataset;
          this.paymentMethod = paymentMethod;
          this.changeSubmitText(submitText);
          this.changePaymentMethod();
        });
      });

      billingaddressisshippingaddressCheckboxElement.addEventListener('change', () => {
        shippingAddressElement.hidden = billingaddressisshippingaddressCheckboxElement.checked;
      });
    }
  }

  set loading(isLoading = true) {
    const {
      element,
    } = this;
    const inputElements = [...element.elements];
    if (isLoading === true) {
      element.classList.add('-loading');
    } else {
      element.classList.remove('-loading');
    }
    inputElements.forEach((inputElement) => {
      inputElement.disabled = isLoading;
    });
  }

  get loading() {
    return this.element.classList.contains('-loading');
  }

  changeSubmitText(text) {
    const {
      submitElement,
      buttonTextDefault,
    } = this;
    if (text && text.length > 0) {
      submitElement.textContent = text;
    } else {
      submitElement.textContent = buttonTextDefault;
    }
  }

  changePaymentMethod() {
    const {
      paymentMethodElements,
      paymentMethod,
    } = this;
    paymentMethodElements.forEach((paymentMethodElement) => {
      if (paymentMethodElement.dataset.paymentMethod === paymentMethod) {
        paymentMethodElement.hidden = false;
      } else {
        paymentMethodElement.hidden = true;
      }
    });
  }

  initStripe() {
    const {
      element,
    } = this;
    if (this.stripe === null) {
      const stripeStyle = {
        base: {
          fontFamily: 'Avenir, Arial, sans-serif',
          fontSize: '17px',
          color: '#333494',
        },
      };

      const stripePublishableKey = element.querySelector('input[name="stripePublishableKey"]').value;
      const stripe = Stripe(stripePublishableKey);
      const stripeElements = stripe.elements();

      const stripeCardElement = document.getElementById('stripe-card');

      const stripeCard = stripeElements.create('card', {
        style: stripeStyle,
        hidePostalCode: true,
        disableLink: true,
      });

      stripeCard.mount(stripeCardElement);
      // stripeSepaDebit.mount(stripeSepaDebitElement);

      stripe.card = stripeCard;
      stripe.cardElement = stripeCardElement;

      this.stripe = stripe;
    }
  }

  getErrorElement(message) {
    const errorElement = document.createElement('div');
    errorElement.classList.add('a-error');
    errorElement.textContent = message;
    return errorElement;
  }

  showGlobalError(message = 'Fatal error.') {
    const {
      containerElement,
      getErrorElement,
    } = this;
    containerElement.appendChild(getErrorElement(message));
  }

  handleCardPayment() {
    const {
      element,
      stripe,
      getErrorElement,
    } = this;

    return new Promise(async (resolve, reject) => {
      const clientSecret = await this.getClientSecret().catch((message) => {
        reject(message);
      });
      const billing_details = {
        email: element.email.value,
        address: {
          line1: element.streetaddress.value,
          city: element.city.value,
          country: 'DE',
        },
      };
      if (element.name.value) {
        billing_details.name = element.name.value;
      }
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: stripe.card,
          billing_details,
        },
      });

      if (error) {
        const errorElement = getErrorElement(error.message);
        stripe.cardElement.parentElement.appendChild(errorElement);
        resolve();
      } else {
        await this.submit();
        resolve();
      }
    });
  }

  // returns client secret for stripe
  getClientSecret() {
    return fetch('shop-api/get-client-secret', { cache: 'no-store' }).then((response) => response.json()).then((data) => {
      if (data.clientSecret) {
        return data.clientSecret;
      }
      throw data.message ? data.message : undefined;
    });
  }

  // returns redirect string
  submit() {
    const {
      element,
      getErrorElement,
    } = this;

    function showFieldErrors(details) {
      let isFocused = false;
      Object.keys(details).forEach((key) => {
        const inputElement = element.querySelector(`input[name="${key}"]`);
        const message = Object.values(details[key].message)[0];
        if (inputElement) {
          const labelElement = inputElement.closest('label');
          labelElement.appendChild(getErrorElement(message));
          if (!isFocused && inputElement) {
            inputElement.focus();
            isFocused = true;
          }
        } else {
          alert(message || 'Fatal Error.');
        }
      });
    }

    return new Promise(async (resolve, reject) => {
      const inputElements = [...element.elements];
      inputElements.forEach((inputElement) => {
        inputElement.disabled = false;
      });
      const response = await super.submit(element);
      inputElements.forEach((inputElement) => {
        inputElement.disabled = true;
      });
      const data = await response.json().catch((error) => {
        console.info(error);
        reject(error.statusText);
      });

      if (data.status === 201) {
        window.location = data.redirect;
        resolve();
      } else if (data.code === 'error.merx.fieldsvalidation') {
        showFieldErrors(data.details);
        resolve();
      } else {
        reject(data.message ? data.message : undefined);
      }
    });
  }
}

export default Checkout;
