class Subnav {
  constructor(element) {
    const { id } = element;
    const oHeaderElement = document.querySelector('.o-header');

    this.element = element;
    this.aNavLinkOpenElements = oHeaderElement.querySelectorAll('.a-nav-link.-open');
    this.buttonElement = oHeaderElement.querySelector(`button[data-subnav="${id}"]`);
    this.oHeaderBackdropElement = oHeaderElement.querySelector('.o-header__backdrop');

    element.addEventListener('transitionend', (event) => {
      if (event.target === element && !element.classList.contains('-expanded')) {
        element.hidden = true;
      }
    });
    this.buttonElement.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'false') {
        this.show();
      } else {
        this.hide();
      }
    });
    document.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'true'
      && event.target.closest(`button[data-subnav="${id}"]`) !== this.buttonElement
      && event.target.closest('.m-subnav') === null) {
        this.hide();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.hide();
      }
    });
  }

  show() {
    const {
      element,
      buttonElement,
      oHeaderBackdropElement,
      aNavLinkOpenElements,
    } = this;

    if (buttonElement.getAttribute('aria-expanded') === 'false') {
      element.hidden = false;
      buttonElement.classList.add('-active');
      buttonElement.setAttribute('aria-expanded', 'true');
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          oHeaderBackdropElement.classList.remove('-hidden');
          element.classList.add('-expanded');
        });
      });

      aNavLinkOpenElements.forEach((aNavLinkOpenElement) => aNavLinkOpenElement.classList.add('-no-open'));
    }
  }

  hide() {
    const {
      element,
      buttonElement,
      oHeaderBackdropElement,
      aNavLinkOpenElements,
    } = this;

    if (buttonElement.getAttribute('aria-expanded') === 'true') {
      element.classList.remove('-expanded');
      buttonElement.classList.remove('-active');
      buttonElement.setAttribute('aria-expanded', 'false');
      oHeaderBackdropElement.classList.add('-hidden');

      aNavLinkOpenElements.forEach((aNavLinkOpenElement) => aNavLinkOpenElement.classList.remove('-no-open'));
    }
  }
}

export default Subnav;
