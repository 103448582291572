import CartAbstract from './CartAbstract';

class Cart extends CartAbstract {
  constructor(element) {
    super();

    const { lang } = document.querySelector('html');
    const headerElement = document.querySelector('.o-header');
    const url = element.dataset.url ? element.dataset.url : undefined;
    const buttonContinueElement = element.querySelector('.m-cart__button-continue');
    const buttonCheckoutElement = element.querySelector('.m-cart__button-checkout');

    this.buttonElement = headerElement.querySelector('.a-icon-link');
    this.element = element;
    this.buttonContinueElement = buttonContinueElement;
    this.buttonCheckoutElement = buttonCheckoutElement;
    this.countElement = this.buttonElement.querySelector('.a-icon-link__count');
    this.basketBodyElement = this.buttonElement.querySelector('#body');
    this.oHeaderBackdropElement = headerElement.querySelector('.o-header__backdrop');
    this.data = null;
    this.isInvoice = !!element.classList.contains('-invoice');
    this.isMouseOver = false;
    if (lang === 'en') {
      this.i18n = {
        'cart.item.remove': 'Remove',
        'cart.included-vat': 'Included VAT',
        'cart.vat-included': 'VAT incl.',
        'cart.quantity': 'Quantity',
        'cart.quantity-in-cart': 'in cart',
        'cart.change-quantity': 'Change quantity',
        'cart.sum': 'Sum',
        'cart.free-shipping': 'free of charge',
      };
    } else {
      this.i18n = {
        'cart.item.remove': 'Entfernen',
        'cart.included-vat': 'Enthaltene MwSt.',
        'cart.vat-included': 'inkl. MwSt.',
        'cart.quantity': 'Anzahl',
        'cart.quantity-in-cart': 'im Warenkorb',
        'cart.change-quantity': 'Anzahl ändern',
        'cart.sum': 'Gesamtsumme',
        'cart.free-shipping': 'kostenlos',
      };
    }

    element.addEventListener('transitionend', (event) => {
      if (event.target === element && !element.classList.contains('-expanded')) {
        element.hidden = true;
      }
    });
    element.addEventListener('mouseenter', (event) => {
      this.isMouseOver = true;
    });
    element.addEventListener('mouseleave', (event) => {
      this.isMouseOver = false;
    });
    if (buttonContinueElement) {
      buttonContinueElement.addEventListener('click', () => {
        this.hide();
      });
    }
    this.buttonElement.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'false') {
        this.show();
      } else {
        this.hide();
      }
    });
    document.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'true'
      && event.target.closest('.a-icon-link') !== this.buttonElement
      && event.target.closest('.m-cart') === null) {
        this.hide();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.hide();
      }
    });

    (async () => {
      element.classList.add('-loading');
      const response = await super.get(url);
      const data = await response.json().catch((error) => {
        console.info(error);
      });
      element.classList.remove('-loading');
      this.data = data;
      if (response.status === 200) {
        this.updateCount();
        this.createCartList();
      } else {
        this.createCartList();
        console.info(response);
      }
    })();
  }

  updateCount() {
    const {
      countElement,
      basketBodyElement,
      data,
    } = this;
    const quantity = data ? data.quantity : 0;
    if (countElement && basketBodyElement) {
      if (quantity !== 0) {
        basketBodyElement.style.fill = '#ffffff';
        countElement.innerText = quantity > 9 ? '•' : quantity;
      } else {
        basketBodyElement.style.fill = 'transparent';
        countElement.innerText = '';
      }
    }
  }

  createCartList() {
    const {
      element,
      data,
      isInvoice,
      i18n,
    } = this;
    const listElement = element.querySelector('.m-cart__list');

    function createItem(data) {
      let html = '<div class="m-cart-item">';
      if (data.url && data.src) {
        html += `
          <figure aria-hidden="true">
            <a href="${data.url}">
              <img src="${data.src}" alt="">
            </a>
          </figure>
        `;
      }
      html += '<div class="m-cart-item__text">';

      if (data.url) {
        html += `<a href="${data.url}" class="m-cart-item__title a-product-title">`;
      } else {
        html += '<span class="m-cart-item__title a-product-title">';
      }
      if (data.weightText) {
        html += `
          <strong>${data.title}</strong>
          <em>${data.weightText}</em>
        `;
      } else {
        html += `
          <strong>${data.title}</strong>
        `;
      }
      if (data.url) {
        html += '</a>';
      } else {
        html += '</span>';
      }

      html += '<div class="m-cart-item__quantity">';

      if (isInvoice === true) {
        html += `${i18n['cart.quantity']}: ${data.quantity}`;
      } else {
        html += `<select aria-label="${data.quantity} ${i18n['cart.quantity-in-cart']}. ${i18n['cart.change-quantity']}." class="a-select" data-action="update" data-id="${data.id}">`;
        for (let i = 1; i <= data.maxAmount; i += 1) {
          if (i === data.quantity) {
            html += `<option selected value="${i}">${i}</option>`;
          } else {
            html += `<option value="${i}">${i}</option>`;
          }
        }
        html += '</select>';
        html += `<button type="button" data-action="remove" data-id="${data.id}">${i18n['cart.item.remove']}</button>`;
      }
      html += '</div>';

      html += '</div>';
      html += `
        <div class="m-cart-item__price">
          ${data.sum}
        </div>
      `;
      html += '</div>';
      return html;
    }

    function onSelectChange() {
      const { dataset, value } = event.target;
      if (dataset.action === 'update') {
        this.update(dataset.id, value);
      }
    }

    function onButtonClick() {
      const { dataset } = event.target;
      if (dataset.action === 'remove') {
        this.remove(dataset.id);
      }
    }

    if (data) {
      let html = '';

      if (data.message) {
        html += `
          <div class="m-cart__message -${data.status}">
            ${data.message}
          </div>
        `;
      }

      if (data.items.length > 0) {
        data.items.filter((item) => item.type !== 'shipping').forEach((item) => {
          html += createItem(item);
        });

        html += `
          <div class="a-wave"></div>
        `;

        const shipping = data.items.find((item) => item.type === 'shipping');
        if (shipping) {
          const shippingText = parseFloat(shipping.price) === 0.0 ? i18n['cart.free-shipping'] : shipping.price;

          html += `
            <div class="m-cart__shipping a-small">
              <div>${shipping.title}</div>
              <div><strong>${shippingText}</strong></div>
            </div>
          `;
        }

        data.taxRates.forEach((taxRate) => {
          html += `
            <div class="m-cart__tax a-small">
              <div>${i18n['cart.included-vat']} (${taxRate.taxRate} %)</div>
              <div><strong>${taxRate.sum}</strong></div>
            </div>
          `;
        });

        if (data.sum) {
          html += `
            <div class="m-cart__sum">
              <div><strong>${i18n['cart.sum']}</strong> <span class="a-small a-gray a-nowrap">(${i18n['cart.vat-included']})</span></div>
              <div><strong>${data.sum}</strong></div>
            </div>
          `;
        }
      } else {
        // empty cart
      }

      listElement.innerHTML = html;

      if (data.items) {
        listElement.querySelectorAll('button').forEach((buttonElement) => {
          buttonElement.addEventListener('click', onButtonClick.bind(this));
        });
        listElement.querySelectorAll('select').forEach((selectElement) => {
          selectElement.addEventListener('change', onSelectChange.bind(this));
        });
      }
    }
  }

  show() {
    const {
      element,
      buttonElement,
      buttonContinueElement,
      buttonCheckoutElement,
      oHeaderBackdropElement,
    } = this;

    function isElementInViewport(_element) {
      const {
        top,
        left,
        bottom,
        right,
      } = _element.getBoundingClientRect();
      return (
        top >= 0
          && left >= 0
          && bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    if (buttonElement.getAttribute('aria-expanded') === 'false') {
      element.hidden = false;
      buttonElement.setAttribute('aria-expanded', 'true');
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          oHeaderBackdropElement.classList.remove('-hidden');
          element.classList.add('-expanded');
          if (buttonCheckoutElement && buttonCheckoutElement.hidden === false) {
            buttonCheckoutElement.focus();
          } else if (buttonContinueElement && buttonContinueElement.hidden === false) {
            buttonContinueElement.focus();
          }
          if (!isElementInViewport(buttonElement)) {
            buttonElement.scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      });
    }
  }

  hide() {
    const {
      element,
      buttonElement,
      oHeaderBackdropElement,
    } = this;

    if (buttonElement.getAttribute('aria-expanded') === 'true') {
      element.classList.remove('-expanded');
      buttonElement.setAttribute('aria-expanded', 'false');
      oHeaderBackdropElement.classList.add('-hidden');
      this.isMouseOver = false;
    }
  }

  add(item, quantity = 1) {
    const {
      element,
    } = this;

    return new Promise(async (resolve, reject) => {
      element.classList.add('-loading');

      const response = await super.add(item, quantity);
      const data = await response.json().catch((error) => {
        console.info(error);
        reject(error.statusText);
      });

      if (response.status === 200) {
        this.data = data;
        this.updateCount();
        this.createCartList();
        this.show();
        resolve();
      } else {
        console.info(data);
        reject(data.message);
      }

      element.classList.remove('-loading');
    });
  }

  update(item, quantity) {
    const {
      element,
    } = this;

    return new Promise(async (resolve, reject) => {
      element.classList.add('-loading');
      const response = await super.update(item, quantity);
      const data = await response.json().catch((error) => {
        console.info(error);
        reject(error.statusText);
      });
      element.classList.remove('-loading');

      if (response.status === 200) {
        this.data = data;
        this.updateCount();
        this.createCartList();
        resolve();
      } else {
        console.info(data);
        reject(data.message);
      }
    });
  }

  remove(item) {
    const {
      element,
    } = this;

    return new Promise(async (resolve, reject) => {
      element.classList.add('-loading');
      const response = await super.remove(item);
      const data = await response.json().catch((error) => {
        console.info(error);
        reject(error.statusText);
      });
      element.classList.remove('-loading');

      if (response.status === 200) {
        this.data = data;
        this.updateCount();
        this.createCartList();
        resolve();
      } else {
        console.info(data);
        reject(data.message);
      }
    });
  }
}

export default Cart;
