class ThumbsHome {
  constructor(element) {
    const itemElements = element.children;

    function resizeItem(itemElement) {
      const thumbElement = itemElement.firstElementChild;
      if (thumbElement) {
        const rowHeight = parseInt(window.getComputedStyle(element).getPropertyValue('grid-auto-rows'));
        const gap = parseInt(window.getComputedStyle(element).getPropertyValue('column-gap'));
        const { height } = thumbElement.getBoundingClientRect();
        const paddingTop = parseInt(window.getComputedStyle(itemElement).getPropertyValue('padding-top'));
        const rowSpan = Math.ceil((height + paddingTop) / rowHeight + (gap / rowHeight));
        itemElement.style.gridRowEnd = `span ${rowSpan}`;
      }
    }

    function resizeAllGridItems() {
      if (window.matchMedia('(min-width: 30em)').matches) {
        [...itemElements].forEach((itemElement) => resizeItem(itemElement));
      } else {
        [...itemElements].forEach((itemElement) => {
          itemElement.style.gridRowEnd = '';
        });
      }
    }

    window.addEventListener('load', resizeAllGridItems);
    window.addEventListener('resize', resizeAllGridItems, {
      passive: true,
    });

    resizeAllGridItems();
  }
}

export default ThumbsHome;
