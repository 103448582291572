class MFooterScrollToTop {
  constructor(element) {
    const buttonElement = element.querySelector('.a-text-button.-up');
    const footerContainerElement = document.querySelector('.o-footer__container');
    const {
      scrollingElement,
    } = document;

    let footerIsVisible = false;
    let previousScrollTop = document.scrollingElement.scrollTop;

    if (footerContainerElement) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          footerIsVisible = entry.isIntersecting;
        });
      });
      observer.observe(footerContainerElement);
    }

    window.addEventListener('scroll', () => {
      const {
        scrollTop,
      } = document.scrollingElement;
      const userScorllsUp = previousScrollTop > scrollTop;
      const userScrolledMoreThanTwoViewports = scrollTop > window.innerHeight * 2;
      // console.log({userScorllsUp}, {userScrolledMoreThanTwoViewports}, {scrollTop}, {footerIsVisible});
      element.classList.toggle('-hidden', !(footerIsVisible || (userScorllsUp && userScrolledMoreThanTwoViewports)));
      previousScrollTop = scrollTop;
    }, {
      passive: true,
    });

    buttonElement.addEventListener('click', () => {
      scrollingElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }
}

export default MFooterScrollToTop;
