class SubnavFixed {
  constructor(element) {
    const mCarouselElement = element.querySelector('.m-carousel');
    const openANavLinkElement = element.querySelector('.a-nav-link.-open');

    this.element = element;
    this.aNavLinkElements = element.querySelectorAll('.a-nav-link');

    this.transitionDuration = 200;

    if (openANavLinkElement) {
      const scrollLeft = openANavLinkElement.offsetLeft + openANavLinkElement.offsetWidth * 0.5 - mCarouselElement.offsetWidth * 0.5;
      mCarouselElement.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }

    if (element.classList.contains('-fixed')) {
      const mHeroCategoryText = document.querySelector('.m-hero-category__text');
      let triggerScrollTop = 122;

      if (mHeroCategoryText) {
        const clientRect = mHeroCategoryText.getBoundingClientRect();
        triggerScrollTop = clientRect.top + clientRect.height * 0.5;
      }

      window.addEventListener('scroll', () => {
        if (document.scrollingElement.scrollTop > triggerScrollTop) {
          this.show();
        } else {
          this.hide();
        }
      }, {
        passive: true,
      });
    }
  }

  show() {
    const {
      element,
      aNavLinkElements,
      transitionDuration,
    } = this;

    if (element.classList.contains('-hidden')) {
      element.classList.remove('-hidden');

      if (element.animate) {
        aNavLinkElements.forEach((aNavLinkElement, index) => {
          aNavLinkElement.animate({
            opacity: [0, 1],
          }, {
            delay: transitionDuration / 3 * index,
            duration: transitionDuration,
            fill: 'backwards',
          });
        });
      }
    }
  }

  hide() {
    const {
      element,
    } = this;

    element.classList.add('-hidden');
  }
}

export default SubnavFixed;
